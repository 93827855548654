$backgroundColor1: white;
$color1: rgb(50, 150, 200);
$color2: black;
$fontFamily: Georgia, 'Times New Roman', Times, serif;

$cutoffPoint: 550px;

.mainContainer {
  overflow: hidden;
  width:100%;
  display: flex;
  flex-direction: column;
  
}
.topNavigation{
  display: flex;
  position:absolute;
  flex-direction: row;
  justify-content: center;
  width:100%;
  z-index: 1;
  background-color: rgba(255,255,255,.7);
}
.topNavTab{
  padding:10px;
  @extend .normalText;
}
#topNavButton{
  flex-grow:1;
  border-radius:10px;
  position:relative;

  &:hover{
    opacity: .6;
    top:-5px;
    left:-5px;
  }
}

#navContainer {
  z-index: 1;
  right:0;
  bottom:0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // border: black solid 2pt;
  margin:20px;
  
}
.navItem{
  justify-content: center;
  background-color:rgba(255,255,255,.6);
  text-align:center;
  vertical-align: center;
  border:black solid 1pt;
  padding:20px 10px 20px 10px;
  margin:10px;
  border-radius: 25px;
  &:hover{
    background-color:rgba(200,200,200,.5);
  }
}
// #navInner {
//   border-color: black;
//   border-style: solid;
//   border-width:3pt;
//   width:100%;
//   height:100%;
//   display:flex;
//   flex-direction:column;
//   border-bottom: .5pt lightblue solid;
// }
// #navMobileInner {
//   margin: 20px;
//   border-color: black;
//   border-style: solid;
//   border-width:1pt;
//   display:flex;
//   flex-direction:column;
// }
.menuButton{
  width: 30px;
  height: 30px;
}
.img {
  width:100%;
}

.navMenuItem {
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  color: black;
  background-color: rgba(255,255,255, 0.6);
  border-bottom: .5pt lightblue solid;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  @extend .normalText;

  &:hover{
    color: $color1;
  cursor:pointer;
  }
}
.splash {
  height:40vh;
  width:100%;
  overflow:hidden;
  vertical-align: center;
  justify-content: center;
  display:flex;
  flex-direction: column;

}
.splashImgContainer {
  @media screen and (min-width:$cutoffPoint){
    //margin-top: 10vh;
  }
  max-height:20;
  height:100vh;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.splashText {
  margin-bottom: 40px;
  width:100%;
  left:0;
  text-align: center;
  color: rgba(255,250,250, 0.9);
  font-weight: bold;
  position: absolute;
  z-index: 2;
  @extend .titleText;
}
.splashImg1 {
  height:auto;
  width:auto;
}
.splashImg2 {
  height:auto;
  width:100%;
}
.about {
  // border:1pt black solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: wrap;
  width:90%;
  margin:auto;
  
  // border:1pt black solid;
}

.aboutText {
  display:flex;
  flex-direction:column;
  justify-content: center;
  max-width:90%;
  margin: 10px;
  color: black;
  
  // border:1pt black solid;
  
  // border-bottom: 1pt darkgray solid;
  
  @extend .normalText;
}
.aboutQuickContainer {
  width:50%;
  max-width:400px;
  align-items: center;
  @extend .normalText;
}

.aboutInnerContainer {
  // border:1pt red solid;
  width:90%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  @media screen and (min-width:900px){
    flex-direction:row;
  }
}
.aboutPortrait {
  flex:1;
  width: 100%;
}
.aboutQuickList {
  flex-direction: column;
  flex:1;
  
}
.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:90%;
  margin:auto;
  
}
.titleText{
  font-size: 20pt;
  
  padding-top: 20px;
  @media screen and (min-width:$cutoffPoint){
    font-size: 30pt;
  }
}
.miscTitle {
  color:$color1;
    width:100%;
    text-align: left;
    @extend .titleText;
}
.contactInner {
  display:flex;
  flex-direction: row;
  width:100%;
  // border:black 1pt solid;
  
  @extend .subtitleText;
}
.subtitleText {
  font-size: 6vw;
  @media screen and (min-width:$cutoffPoint){
    font-size: 25pt;
  }
}
.myWorks {
  // border:1pt black solid;
    min-height:75vh;
    width:90%;
    border-bottom: 1pt darkgray solid;
    display:flex;
    flex-direction:column;
    margin:auto;
}
.worksContainer {
  // border:1pt black solid;
  
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.worksInnerContainer {
  width:90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width:$cutoffPoint){
    flex-direction:row;
  }
}
.worksTitle {
    color:$color2;
    @extend .subtitleText;
}
.worksDate {
    color:$color2;
    @extend .normalText;
}
.worksImgCont {
  //height:auto;
  margin:auto;
  flex:1;
  
  max-width: 300px;
  margin:10px;
  // border:1pt black solid;
  &:hover {
    cursor:pointer;
  }
}
.worksImg {
  width:100%;
}
.normalText {
  font-size: 3vw;
  @media screen and (min-width:$cutoffPoint){
    font-size: 15pt;
  }
}
.worksDesc {
  flex:1;
  margin:10px;
  height:100%;
  @extend .normalText;
}



.Copyright {
    width:100%;
    text-align: center;
    @extend .normalText;
}
